.p-header {
  $this: &;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template: 1fr / 21rem 1fr;
  align-items: flex-start;
  margin: 0 auto;
  z-index: 9999;
  transition: top $hamaEading;
  @media #{$tablet} {
    padding: 2rem 2rem 0;
    grid-template: 6.5rem  / 8.7rem auto;
    grid-template-areas: 
      "logo btn"
      "menu menu";
    justify-content: space-between;
  }
  @media #{$breakpoint} {
    padding: 2rem 2rem 0;
    grid-template: 6.5rem / 8.7rem auto;
    grid-template-areas: 
      "logo btn"
      "menu menu";
    justify-content: space-between;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 11.1rem;
    background: rgba(#fff, 0.2);
    z-index: -1;
    transition: background $hamaEading;
    @media #{$tablet} {
      height: 6.5rem;
    }
    @media #{$breakpoint} {
      height: 6.5rem;
    }
  }
  &.is-sticky {
    position: fixed;
    top: -6.5rem;
    left: 0;
    right: 0;
    grid-template: 1fr / 28.5rem 1fr;
    align-items: center;
    @media #{$tablet} {
      grid-template: 6.5rem / 15rem auto;
      grid-template-areas: 
        "logo btn"
        "menu menu";
      padding-top: 0;
    }
    @media #{$breakpoint} {
      grid-template: 6.5rem / 15rem auto;
      grid-template-areas: 
        "logo btn"
        "menu menu";
      padding-top: 0;
    }
    #{$this} {
      &_logo {
        padding: 0 0 0 6rem;
        @media #{$tablet} {
          padding: 0;
        }
        @media #{$breakpoint} {
          padding: 0;
        }
      }
      &_btn {
        &::after,
        &::before {
          background: #fff;
        }
      }
      &_nav {
        &_link {
          color: #fff;
          &::before {
            background: #fff;
          }
          &.is-tel {
            &::before {
              background: url(../../img/hanare/icn-phone.png) no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
  &.is-dark {
    &::before {
      background: rgba(#000, 0.2);
    }
  }
  &_logo {
    padding: 3rem 0 0 5rem;
    @media #{$tablet} {
      position: relative;
      padding: .5rem 0 0 0;
      z-index: 1;
    }
    @media #{$breakpoint} {
      position: relative;
      padding: .5rem 0 0 0;
      z-index: 1;
    }
  }
  &_btn {
    display: none;
    @media #{$tablet} {
      display: block;
      position: relative;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      z-index: 1; 
    }
    @media #{$breakpoint} {
      display: block;
      position: relative;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      z-index: 1;
    }
    &::before {
      @media #{$tablet} {
        content: "";
        position: absolute;
        top: calc(50% + .2rem);
        left: 0;
        width: 2.5rem;
        height: 0.2rem;
        background: #000;
        transition: transform $hamaEading, background $hamaEading;
      }
      @media #{$breakpoint} {
        content: "";
        position: absolute;
        top: calc(50% + .2rem);
        left: 0;
        width: 2.5rem;
        height: 0.2rem;
        background: #000;
        transition: transform $hamaEading, background $hamaEading;
      }
    }
    &::after {
      @media #{$tablet} {
        content: "";
        position: absolute;
        top: calc(50% - .4rem);
        right: 0;
        width: 1.5rem;
        height: 0.2rem;
        background: #000;
        transition: transform $hamaEading, background $hamaEading;
      }
      @media #{$breakpoint} {
        content: "";
        position: absolute;
        top: calc(50% - .4rem);
        right: 0;
        width: 1.5rem;
        height: 0.2rem;
        background: #000;
        transition: transform $hamaEading, background $hamaEading;
      }
    }
    &.is-active {
      &::before {
        @media #{$tablet} {
          top: calc(50% - .1rem);
          left: 0;
          right: 0;
          width: 2.5rem;
          margin: 0 auto;
          background: #fff;
          transform: rotate(45deg);
        }
        @media #{$breakpoint} {
          top: calc(50% - .1rem);
          left: 0;
          right: 0;
          width: 2.5rem;
          margin: 0 auto;
          background: #fff;
          transform: rotate(45deg);
        }
      }
      &::after {
        @media #{$tablet} {
          top: calc(50% - .1rem);
          left: 0;
          right: 0;
          width: 2.5rem;
          margin: 0 auto;
          background: #fff;
          transform: rotate(-45deg);
        }
        @media #{$breakpoint} {
          top: calc(50% - .1rem);
          left: 0;
          right: 0;
          width: 2.5rem;
          margin: 0 auto;
          background: #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &_nav {
    @media #{$tablet} {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      height: 100dvh;
      padding-bottom: 9rem;
    }
    @media #{$breakpoint} {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      height: 100dvh;
      padding-bottom: 9rem;
    }
    &_list {
      @media #{$tablet} {
        writing-mode: vertical-rl;
      }
      @media #{$breakpoint} {
        writing-mode: vertical-rl;
      }
      &:last-of-type {
        @media #{$tablet} {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
        @media #{$breakpoint} {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
      &_wrapper {
        display: grid;
        grid-template: 1fr / repeat(5, auto) 23.2rem;
        justify-content: flex-end;
        align-items: center;
        gap: 0 3.2rem;
        @media #{$tablet} {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          height: 100%;
          grid-template: 1fr / repeat(5, auto);
          gap: 0 5rem;
          justify-content: center;
          align-items: flex-start;
          padding-top: 18.5rem;
          background: rgba(#000, .8);
        }
        @media #{$breakpoint} {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          height: 100%;
          grid-template: 1fr / repeat(5, auto);
          gap: 0 5rem;
          justify-content: center;
          align-items: flex-start;
          padding-top: 18.5rem;
          background: rgba(#000, .8);
        }
      }
    }
    &_link {
      position: relative;
      display: block;
      padding-bottom: 0.5rem;
      letter-spacing: 0.04em;
      transition: opacity $hamaEading;
      @media #{$tablet} {
        color: #fff;
      }
      @media #{$breakpoint} {
        color: #fff;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.1rem;
        background: #000;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform $hamaEading;
        @media #{$tablet} {
          display: none;
        }
        @media #{$breakpoint} {
          display: none;
        }
      }
      &:hover {
        opacity: .6;
        @media #{$tablet} {
          opacity: 1;
        }
        @media #{$breakpoint} {
          opacity: 1;
        }
        &::before {
          transform-origin: left;
          transform: scaleX(1);
          @media #{$tablet} {
            transform: scaleX(0);
          }
          @media #{$breakpoint} {
            transform: scaleX(0);
          }
        }
      }
      &.is-tel {
        position: relative;
        display: block;
        height: 11.1rem;
        padding: 0 0 0 6.5rem;
        background: #000;
        line-height: 11.1rem;
        text-align: left;
        font-size: 2rem;
        letter-spacing: 0.04em;
        color: #fff;
        @media #{$tablet} {
          width: 100%;
          height: 9rem;
          padding: 0;
          border-top: 1px solid #fff;
          line-height: 8.8rem;
          font-size: 2.2rem;
          writing-mode: horizontal-tb;
          text-align: center;
        }
        @media #{$breakpoint} {
          width: 100%;
          height: 9rem;
          padding: 0;
          border-top: 1px solid #fff;
          line-height: 8.8rem;
          font-size: 2.2rem;
          writing-mode: horizontal-tb;
          text-align: center;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 3.3rem;
          width: 2.3rem;
          height: 2.3rem;
          background: url(../../img/hanare/icn-phone.png) no-repeat;
          background-size: contain;
          transform: translateY(-50%);
          @media #{$tablet} {
            position: static;
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.6rem;
            transform: translateY(.5rem);
          }
          @media #{$breakpoint} {
            position: static;
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 0.6rem;
            transform: translateY(.5rem);
          }
        }
      }
    }
  }
}
