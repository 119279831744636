.p-footer {
  padding: 8.2rem 0 5.8rem;
  background: #000;
  @media #{$tablet} {
    padding: 6rem 0 2.8rem;
  }
  @media #{$breakpoint} {
    padding: 6rem 2rem 2.8rem;
  }
  &_wrapper {
    position: relative;
  }
  &_pagetop {
    position: absolute;
    top: -10.6rem;
    right: 0;
    width: 5rem;
    height: 5rem;
    background: #fff;
    border: 1px solid #000;
    cursor: pointer;
    transition: opacity $hamaEading;
    @media #{$breakpoint} {
      top: -8.5rem;
    }
    &:hover {
      opacity: .6;
      @media #{$breakpoint} {
        opacity: 1;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: calc(100% - .5rem);
      height: calc(100% - .5rem);
      margin: 0 auto;
      border: 1px solid #000;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      width: 1.8rem;
      height: 1.4rem;
      margin: 0 auto;
      background: url(../../img/hanare/icn-pagetop.png) no-repeat;
      background-size: contain;
    }
  }
  &_sns {
    &_wrapper {
      display: grid;
      grid-template: 1fr / auto auto;
      justify-content: center;
      align-items: center;
      gap: 0 3.8rem;
      margin-bottom: 4rem;
    }
    &_link {
      &.is-taberogu {
        display: block;
        width: 6.8rem;
        height: 3.5rem;
        border: 1px solid #fff;
        line-height: 3.3rem;
        font-size: 1.2rem;
        color: #fff;
        text-align: center;
      }
      &.is-insta {
        display: block;
        width: 3.4rem;
        height: 3.4rem;
      }
    }
  }
  &_txt {
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    text-align: center;
    @media #{$tablet} {}
  }
}
