.p-container {
  // width: unquote("min(90.77%, 124rem)");
  width: 90.77%;
  max-width: 124rem;
  margin: 0 auto;
  @media #{$breakpoint} {
    width: 100%;
  }
}
.p-content {
  // width: unquote("min(81.99%, 112rem)");
  width: 81.99%;
  max-width: 112rem;
  margin: 0 auto;
  @media #{$breakpoint} {
    width: 100%;
  }
}
.p-column_2 {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 0 8.3rem;
  @media #{$tablet} {
    display: block;
  }
  @media #{$breakpoint} {
    display: block;
  }
}
